/* Generated by Font Squirrel (http://www.fontsquirrel.com) on January 6, 2016 */



@font-face {
    font-family: 'claire_handlight';
    src: url('clairehandlight-webfont.eot');
    src: url('clairehandlight-webfont.eot?#iefix') format('embedded-opentype'),
         url('clairehandlight-webfont.woff2') format('woff2'),
         url('clairehandlight-webfont.woff') format('woff'),
         url('clairehandlight-webfont.ttf') format('truetype'),
         url('clairehandlight-webfont.svg#claire_handlight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'claire_handregular';
    src: url('clairehandregular-webfont.eot');
    src: url('clairehandregular-webfont.eot?#iefix') format('embedded-opentype'),
         url('clairehandregular-webfont.woff2') format('woff2'),
         url('clairehandregular-webfont.woff') format('woff'),
         url('clairehandregular-webfont.ttf') format('truetype'),
         url('clairehandregular-webfont.svg#claire_handregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'claire_handbold';
    src: url('clairehandbold-webfont.eot');
    src: url('clairehandbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('clairehandbold-webfont.woff2') format('woff2'),
         url('clairehandbold-webfont.woff') format('woff'),
         url('clairehandbold-webfont.ttf') format('truetype'),
         url('clairehandbold-webfont.svg#claire_handbold') format('svg');
    font-weight: normal;
    font-style: normal;

}